import domReady from '@roots/sage/client/dom-ready';

let windowHeight = window.innerHeight;

/**
 * Application entrypoint
 */
domReady(async () => {
  megaMenu.setAttribute(
    'style',
    'top: ' +
      -windowHeight +
      'px !important; bottom: ' +
      windowHeight +
      'px !important;'
  );

  // ...
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);

// Menu Button
let menuOpen = document.getElementById('open-menu');
let menuClose = document.getElementById('close-menu');
let logoBlue = document.getElementById('brand-blue');
let logoWhite = document.getElementById('brand-white');
// Mobile Menu Container
let megaMenu = document.getElementById('mobile-nav');
let navBar = document.getElementById('mobile-nav-bar');

// addEventListener('resize', (event) => {
//   scToggleMenu();
// });

window.scToggleMenu = function () {
  // Open the Menu
  if (
    !('scMenuOpen' in megaMenu.dataset) ||
    megaMenu.dataset.scMenuOpen === 'false'
  ) {
    megaMenu.dataset.scMenuOpen = true;
    // Apply open styling to required elements

    megaMenu.setAttribute('style', 'top: 0 !important; bottom: 0 !important;');
    // navBar.setAttribute('style', 'position: fixed;');

    menuOpen.classList.remove('block');
    menuOpen.classList.add('hidden');

    menuClose.classList.remove('hidden');
    menuClose.classList.add('block');

    logoBlue.classList.remove('block');
    logoBlue.classList.add('hidden');

    logoWhite.classList.remove('hidden');
    logoWhite.classList.add('block');
  }

  // Close the Menu
  else if (megaMenu.dataset.scMenuOpen === 'true') {
    megaMenu.dataset.scMenuOpen = false;
    // Apply closed styling to required elements
    megaMenu.setAttribute(
      'style',
      'top: ' +
        -windowHeight +
        'px !important; bottom: ' +
        windowHeight +
        'px !important;'
    );

    // navBar.setAttribute('style', 'position: relative;');

    menuOpen.classList.remove('hidden');
    menuOpen.classList.add('block');

    menuClose.classList.remove('block');
    menuClose.classList.add('hidden');

    logoBlue.classList.remove('hidden');
    logoBlue.classList.add('block');

    logoWhite.classList.remove('block');
    logoWhite.classList.add('hidden');
  }
};
